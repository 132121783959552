/* 
---------------------------------------------
cookies
--------------------------------------------- 
*/
.aviso-cookies {
	display: none;
	background: #fff;
	padding: 20px;
	width: calc(100% - 40px);
	max-width: 300px;
	line-height: 150%;
	border-radius: 10px;
	position: fixed;
	bottom: 20px;
	right: 20px;

	z-index: 100;
	padding-top: 60px;
	box-shadow: 0px 2px 20px 10px rgba(210, 220, 171,.35);
	text-align: center;
}
.aviso-cookies.activo {
	display: block;
}
.aviso-cookies .galleta {
	max-width: 100px;
	position: absolute;
	top: -50px;
	left: calc(50% - 50px);
}
.aviso-cookies .titulo {
	margin-bottom: 15px;
	color: #0cb8b6;
}
.aviso-cookies .parrafo {
	margin-bottom: 15px;
}
.aviso-cookies .boton {
	width: 100%;
	background: #d2dcab;
	border: none;
	color: #0cb8b6;
	font-family: 'Roboto', sans-serif;
	text-align: center;
	padding: 0px 0px;
	font-weight: 700;
	cursor: pointer;
	transition: .3s ease all;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 14px;
}
.aviso-cookies .boton:hover {
	background: #0cb8b6;
	color: #fff;
}
.aviso-cookies .enlace {
	color: #d2dcab;
	text-decoration: none;
	font-size: 14px;
}
.aviso-cookies .enlace:hover {
	text-decoration: underline;
	color: #0cb8b6;
}
.fondo-aviso-cookies {
	display: none;
	background: rgba(210, 220, 171,.30);
	position: fixed;
	z-index: 99;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}
.fondo-aviso-cookies.activo {
	display: block;
}